var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.content.mediaMaterial ||
      _vm.content.externalMediaMaterial ||
      _vm.content.meetingMaterial
  )?_c('div',[_c('div',{staticClass:"mt-8 px-8 py-8",staticStyle:{"background":"#eee"}},[(_vm.content.mediaMaterial && _vm.content.mediaMaterial.length)?_c('div',[_c('h3',{staticClass:"mb-5",staticStyle:{"color":"#222"}},[_vm._v(_vm._s(_vm.header[0]))]),(_vm.content.mediaMaterial.length)?_c('ul',{staticStyle:{"color":"#222"}},_vm._l((_vm.content.mediaMaterial),function(item,index){return _c('div',{key:index},[(item.file)?_c('li',{staticClass:"mb-4"},[_c('span',{staticClass:"hover medium"},[_c('a',{attrs:{"href":`${_vm.$store.getters.config.baseURL}${item.file.url}`,"target":"_blank"},on:{"click":function($event){return _vm.fireDownloadEvent(item)}}},[_vm._v(_vm._s(item.name))]),(item.summary)?_c('div',{staticClass:"mt-1"},[_vm._v(_vm._s(item.summary))]):_vm._e()]),_c('br')]):_vm._e()])}),0):_vm._e()]):_vm._e(),(
        _vm.content.externalMediaMaterial && _vm.content.externalMediaMaterial.length
      )?_c('div',[_c('h3',{staticClass:"mt-10 mb-5",staticStyle:{"color":"#222"}},[_vm._v(_vm._s(_vm.header[1]))]),(_vm.content.externalMediaMaterial.length)?_c('ul',{staticStyle:{"color":"#222"}},_vm._l((_vm.content.externalMediaMaterial),function(item,index){return _c('div',{key:index},[(item.url)?_c('li',{staticClass:"mb-4"},[_c('a',{attrs:{"href":`${item.url}`,"target":"_blank"}},[_vm._v(_vm._s(item.name))]),(item.summary)?_c('div',{staticClass:"mt-1"},[_vm._v(_vm._s(item.summary))]):_vm._e(),_c('br')]):_vm._e()])}),0):_vm._e()]):_vm._e(),(_vm.content.meetingMaterial && _vm.content.meetingMaterial.length)?_c('div',[_c('h3',{staticClass:"mt-10 mb-5",staticStyle:{"color":"#222"}},[_vm._v(_vm._s(_vm.header[2]))]),(_vm.content.meetingMaterial.length)?_c('ul',{staticStyle:{"color":"#222"}},_vm._l((_vm.content.meetingMaterial),function(item,index){return _c('div',{key:index},[_c('li',{staticClass:"mb-4"},[_c('a',{attrs:{"href":`${_vm.$store.getters.config.baseURL}${item.file[0].url}`,"target":"_blank"},on:{"click":function($event){return _vm.fireDownloadEvent(item)}}},[_vm._v(_vm._s(item.name))]),(item.summary)?_c('div',{staticClass:"mt-1"},[_vm._v(_vm._s(item.summary))]):_vm._e(),_c('br')])])}),0):_vm._e()]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }